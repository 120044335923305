/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-play:before { content: "\f100"; }
.flaticon-null:before { content: "\f101"; }
.flaticon-null-1:before { content: "\f102"; }
.flaticon-null-2:before { content: "\f103"; }
.flaticon-search:before { content: "\f104"; }
.flaticon-null-3:before { content: "\f105"; }
.flaticon-left-chevron:before { content: "\f106"; }
.flaticon-down-arrow:before { content: "\f107"; }
.flaticon-search-1:before { content: "\f108"; }
.flaticon-mails:before { content: "\f109"; }
.flaticon-null-4:before { content: "\f10a"; }
.flaticon-null-5:before { content: "\f10b"; }
.flaticon-game:before { content: "\f10c"; }
.flaticon-card-game:before { content: "\f10d"; }
.flaticon-slot-machine:before { content: "\f10e"; }
.flaticon-3d:before { content: "\f10f"; }
.flaticon-website:before { content: "\f110"; }
.flaticon-game-controller:before { content: "\f111"; }
.flaticon-right:before { content: "\f112"; }
.flaticon-left:before { content: "\f113"; }
.flaticon-quote:before { content: "\f114"; }
.flaticon-quote-1:before { content: "\f115"; }
.flaticon-location:before { content: "\f116"; }
.flaticon-24-hours:before { content: "\f117"; }
.flaticon-appointment:before { content: "\f118"; }
.flaticon-cash:before { content: "\f119"; }
.flaticon-game-1:before { content: "\f11a"; }
.flaticon-game-computer:before { content: "\f11b"; }
.flaticon-teamwork:before { content: "\f11c"; }
.flaticon-play-button:before { content: "\f11d"; }
.flaticon-coin:before { content: "\f11e"; }
.flaticon-network:before { content: "\f11f"; }
.flaticon-medal:before { content: "\f120"; }
.flaticon-medal-1:before { content: "\f121"; }
.flaticon-fall:before { content: "\f122"; }